<template>
  <div class="new-image-portifolio-component">
    <div class="row">
      <div class="col-lg-6">
        <file-input
          :id="archivesId"
          label="Adicione uma imagem:"
          lightLabel="(Tamanho máximo 5MB)"
          :file_count_limit="1"
          :file_size_limit="5"
          v-model="item.file"
          class="mb-0 pb-0"
        />
      </div>
      <div class="col-lg-6">
        <form-group
          :id="descriptionId"
          label="Pequena descrição"
          placeholder="Digite uma descrição"
          v-model="item.description"
          @change="$emit('resetErrors')"
          class="mb-0 pb-0 mt-n1"
          :maxLength="50"
          blackShape
        />
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <p
        v-if="item.description && item.description.length"
        class="text-dark len mb-0"
      >
        {{ item.description.length }}/50
      </p>
      <p v-else class="text-dark len mb-0">0/50</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    counter: {
      type: Number,
      default: 0
    }
  },
  computed: {
    archivesId() {
      if (this.item.id) {
        return `Archive-${this.item.id}`;
      } else {
        return `NewArchive-${this.counter}`;
      }
    },
    descriptionId() {
      if (this.item.id) {
        return `Desc-${this.item.id}`;
      } else {
        return `NewDesc-${this.counter}`;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.new-image-portifolio-component {
  .len {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.015em;
    color: #595959;
  }
}
</style>
