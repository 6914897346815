<template>
  <div @keydown="isEditing = true" class="component-new-service">
    <div class="d-flex justify-content-end">
      <button
        @click="confirmation = true"
        class="btn btn-service mb-n4"
        v-if="service.form.id"
      >
        <i class="icon-close"></i>
        Deletar serviço
      </button>
    </div>
    <select-dropdown
      id="name"
      v-model="service.form.name"
      hideButtons
      label="Serviço"
      emitOnSelect
      :selectOne="true"
      :options="serviceOptions"
      placeholder="Selecione os serviços..."
    />
    <form-group
      id="description"
      v-model="service.form.description"
      label="Descrição"
      textarea
      placeholder="Descreva seus serviços"
      :errors="service.errors.description"
      @change="$emit('resetErrors')"
      blackShape
      :disabled="true"
    />
    <div class="d-flex justify-content-end text-secondary mb-5">
      <small v-if="has_description" class="text-secondary font-weight-bold">
        Letras: {{ service.form.description.length }} | Palavras:
        {{ service.form.description.match(/(\w+)/g).length }}
      </small>
      <small v-else class="text-secondary font-weight-bold">
        Letras: 0 | Palavras: 0
      </small>
    </div>
    <file-input
      :id="archivesId"
      label="Adicione até 5 imagens para ilustrar o seu serviço:"
      lightLabel="(Tamanho máximo 25MB)"
      v-model="service.form.archives_service"
      class="mb-4"
      :file_count_limit="5"
      :file_size_limit="25"
      multiple
    />
    <form-group
      id="video_url"
      v-model="service.form.url"
      label="Adicionar um vídeo"
      placeholder="Cole o link aqui"
      :errors="service.errors.url"
      @change="$emit('resetErrors')"
      class="mb-4"
      blackShape
    />

    <div
      v-if="isEditing || !service.form.id"
      class="row justify-content-center"
    >
      <div class="col-lg-4 mb-4 mb-lg-0">
        <div class="d-none d-lg-block">
          <button
            class="btn btn-outline-secondary btn-block"
            @click="cancelService"
          >
            Cancelar
          </button>
        </div>
        <div class="d-lg-none">
          <button
            class="btn btn-outline-secondary btn-block"
            @click="cancelService"
          >
            Cancelar
          </button>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="d-none d-lg-block">
          <button
            class="btn btn-primary btn-block"
            @click="$emit('submit', service)"
          >
            Salvar
          </button>
        </div>
        <div class="d-lg-none">
          <button
            class="btn btn-primary btn-block"
            @click="$emit('submit', service)"
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
    <confirmation
      v-model="confirmation"
      title="Excluir serviço"
      text="Deseja mesmo excluir esse serviço?"
      icon-type="warning"
      confirm-button-text="Confirmar"
      cancel-button-text="Cancelar"
      cancel-button-class="btn-outline-dark"
      confirm-button-class="btn-primary"
      @cancel="confirmation = false"
      @confirm="confirmationExclude"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import { convertHTMLToPlainText } from "@/functions/helpers.js";
export default {
  props: {
    service: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    this.$store.dispatch("services/getServices");
    if (this.service.form.id) {
      this.service.form.name = [this.service.form.certificate.id];
    }
  },
  data() {
    return {
      isEditing: false,
      confirmation: false
    };
  },
  methods: {
    cancelService() {
      this.$emit("cancel");
    },
    confirmationExclude() {
      this.$emit("exclude");
    }
  },
  computed: {
    ...mapState("services", ["services"]),
    has_description() {
      return this.service?.form?.description?.length;
    },
    coverId() {
      if (this.service.form.id) {
        return `Cover-${this.service.form.id}`;
      } else {
        return "NewCover";
      }
    },
    archivesId() {
      if (this.service.form.id) {
        return `Archives-${this.service.form.id}`;
      } else {
        return "Newarchive";
      }
    },
    serviceOptions() {
      return this.services.map(({ id, title }) => ({
        name: title,
        value: id,
        id
      }));
    }
  },
  watch: {
    "service.form.cover"(newV) {
      if (newV?.length) {
        //this.service.form.is_editing = true;
        this.isEditing = true;
      } else {
        //this.service.form.is_editing = false;
        this.isEditing = false;
      }
    },
    "service.form.name"(val) {
      if (val.length) {
        let service = this.services.find(c => c.id == val);
        this.service.form.description = convertHTMLToPlainText(
          service?.description
        );
      }
    },
    "service.form.archives_service"(newV) {
      if (newV?.length) {
        //this.service.form.is_editing = true;
        this.isEditing = true;
      } else {
        //this.service.form.is_editing = false;
        this.isEditing = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.component-new-service {
  .btn-service {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.015em;
    i {
      font-size: 28px;
    }
    color: #f20530;
    i {
      color: #f20530;
    }
  }
}
</style>
