<template>
  <action-dispatcher action="professional/getCategories">
    <div class="views-professionals-my-profile pb-2 pb-lg-5">
      <header
        v-if="!showProfile"
        class="d-flex flex-column align-items-center pt-2 pt-lg-4"
        :style="`background-image: url(${profile.form.cover})`"
      >
        <image-input
          :isCover="true"
          id="profile_cover"
          v-model="profile.form.cover"
        >
          <button class="btn btn-cover d-flex align-items-center">
            <i class="icon-image mr-2"></i> Adicionar capa
          </button>
        </image-input>
        <div class="half-down-card px-3 px-lg-4 pt-4">
          <div class="d-flex justify-content-center">
            <image-input id="profile_img" v-model="profile.form.photo">
              <div class="profile-img-placeholder">
                <img
                  v-if="profile.form.photo"
                  :src="profile.form.photo"
                  :alt="`Foto de perfil de ${$user.user.name}`"
                />
                <div class="icon-wrapper">
                  <i class="icon-image"></i>
                </div>
              </div>
            </image-input>
          </div>
          <h4 class="text-dark">Perfil</h4>
        </div>
      </header>
      <section
        v-if="!showProfile"
        class="d-flex flex-column align-items-center"
      >
        <div class="half-up-card px-3 px-lg-4 pb-4">
          <form-group
            id="commercial_name"
            v-model="profile.form.name"
            label="Seu nome"
            placeholder="Digite o nome que deseja exibir"
            :errors="profile.errors.name"
            :blackShape="true"
          />
          <div class="row mb-4">
            <div class="col-lg-6">
              <form-group
                id="category"
                v-model="profile.form.category"
                label="Profissão"
                select
                :options="categories"
                placeholder="Selecione sua profissão..."
                :errors="profile.errors.category"
                blackShape
              />
            </div>
            <div class="col-lg-6">
              <select-dropdown
                id="tags"
                hideButtons
                label="Tags"
                class="mt-2 pt-1"
                emitOnSelect
                shortPlaceholder
                propName="name"
                :options="tagList"
                placeholder="Selecione as tags..."
                :errors="profile.errors.tags"
                v-model="profile.form.tags"
              />
            </div>
          </div>

          <form-group
            id="company"
            v-model="profile.form.company_name"
            label="Nome (opcional)"
            placeholder="Exemplo: Nome da sua empresa"
            :errors="profile.errors.company"
          />

          <div class="bio-input">
            <form-group
              id="bio"
              v-model="profile.form.bio"
              label="Sua bio"
              type="text"
              placeholder="Breve descrição sobre você"
              :errors="profile.errors.bio"
              :maxLength="100"
            />
            <div class="d-flex justify-content-end">
              <small v-if="profile.form.bio" class="text-dark mr-2"
                >{{ profile.form.bio.length || 0 }}/100</small
              >
            </div>
          </div>

          <div class="bio-input">
            <form-group
              id="about"
              v-model="profile.form.about"
              label="Sobre você"
              textarea
              placeholder="Fale sobre você"
              :errors="profile.errors.about"
              blackShape
            />
            <div class="d-flex justify-content-end">
              <small
                v-if="profile.form.about"
                class="font-weight-bold text-secondary"
                :class="{
                  'text-danger': profile.form.about.length > 300
                }"
              >
                {{ profile.form.about.length }}/300
              </small>
              <small v-else> 0/300 </small>
            </div>
          </div>

          <hr class="mb-5" />
          <h4 class="text-dark mb-3">Contato</h4>
          <div class="row mb-4">
            <div class="col-lg-6">
              <form-group
                id="website"
                v-model="profile.form.site"
                label="Site"
                placeholder="Digite o URL do seu site"
                :errors="profile.errors.site"
                blackShape
              />
            </div>
            <div class="col-lg-6">
              <form-group
                id="email"
                v-model="profile.form.email"
                label="E-mail"
                placeholder="Digite o seu e-mail"
                :errors="profile.errors.email"
                blackShape
              />
            </div>
          </div>

          <div class="row mb-5">
            <div class="col-lg-6">
              <form-group
                masked
                id="phone"
                blackShape
                label="Celular"
                mask="(##) # ####-####"
                v-model="profile.form.phone"
                :errors="profile.errors.phone"
                placeholder="Digite o número do seu celular"
              />
            </div>
            <div class="col-lg-6">
              <form-group
                id="instagram"
                v-model="profile.form.social_media"
                label="Instagram"
                placeholder="Digite o seu nome de usuário no instagram"
                :errors="profile.errors.social_media"
                blackShape
              />
            </div>
          </div>
          <hr class="mb-5" />
          <h4 class="text-dark mb-3">Localização</h4>
          <div class="row mb-5">
            <div class="col-lg-3">
              <form-group
                id="CEP"
                v-model="profile.form.zipcode"
                label="CEP"
                placeholder="00000-000"
                :errors="zipcode_error ? ['CEP inválido'] : []"
                blackShape
                mask="#####-###"
              />
            </div>
            <div class="col-lg-3">
              <form-group
                id="city"
                v-model="profile.form.city"
                label="Cidade"
                placeholder="Cidade"
                :errors="profile.errors.city"
                blackShape
                readonly
              />
            </div>
            <div class="col-lg-3">
              <form-group
                id="state"
                v-model="profile.form.state"
                label="Estado"
                placeholder="Estado"
                :errors="profile.errors.state"
                blackShape
                readonly
              />
            </div>
            <div class="col-lg-3">
              <form-group
                id="country"
                v-model="profile.form.country"
                label="Pais"
                placeholder="Pais"
                :errors="profile.errors.country"
                blackShape
                readonly
              />
            </div>
          </div>
          <div class="row align-items-center justify-content-center mb-5">
            <div class="col-lg-4 mb-3 mb-lg-0">
              <button
                class="btn btn-outline-secondary btn-block"
                @click="$router.go(-1)"
              >
                Cancelar
              </button>
            </div>
            <div class="col-lg-4 mb-lg-0 mb-3">
              <button
                @click="showProfile = !showProfile"
                class="btn btn-outline-secondary btn-block"
              >
                Ver prévia do perfil
              </button>
            </div>
            <div class="col-lg-4">
              <button class="btn btn-primary btn-block" @click="submitProfile">
                <loading :show="loadingProfile">
                  {{
                    $user.profile && $user.profile.id
                      ? "Atualizar perfil"
                      : "Publicar perfil"
                  }}
                </loading>
              </button>
            </div>
          </div>
          <div class="mb-5 pb-5 pt-3">
            <h4 class="text-dark">Portfolio</h4>
            <new-image-portifolio
              class="mt-3"
              v-for="(item, i) in portifolio.form.archives_portfolio"
              :key="`Image-${i}`"
              :item="portifolio.form.archives_portfolio[i]"
              :counter="i"
            />
            <button
              v-if="portifolio.form.archives_portfolio.length <= 25"
              @click="addImage"
              class="btn btn-image btn-block ml-0 pl-0 my-3"
            >
              <loading :show="loadingPortifolio">
                <div class="d-flex align-items-center">
                  <i class="icon-more mr-2"></i>
                  Adicionar imagem
                </div>
              </loading>
            </button>
            <div v-if="showButtonsPort">
              <div class="row justify-content-center mt-5">
                <div class="col-lg-12">
                  <div class="row justify-content-center">
                    <div class="col-lg-4 mb-lg-0 mb-4">
                      <button
                        @click="cancelPortifolio"
                        class="btn btn-block btn-outline-secondary"
                      >
                        Cancelar
                      </button>
                    </div>
                    <div class="col-lg-4">
                      <button
                        @click="savePortifolio"
                        class="btn btn-block btn-primary"
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-dark"></div>
          <h4 v-if="services.length" class="text-dark mb-3">Serviços</h4>
          <new-service
            class="my-5"
            v-for="(service, i) in services"
            :key="`service-${i}`"
            :service="services[i]"
            @submit="submitService"
            @cancel="cancelService(service, i)"
            @exclude="excludeService(service)"
          />
          <button
            :disabled="!canCreateService"
            :class="{
              'btn btn-service btn-block ml-0 pl-0': true,
              enable: canCreateService
            }"
            @click="addService"
          >
            <loading :show="loadingService">
              <div class="d-flex align-items-center">
                <i class="icon-more mr-2"></i>
                Adicionar serviço
              </div>
            </loading>
          </button>
        </div>
      </section>

      <div v-if="showProfile" class="profile-preview">
        <div
          :style="`background-image: url(${profile.form.cover});`"
          class="cover-profile"
        ></div>
        <professional-profile
          @continue="showProfile = false"
          :isEditing="true"
          :profile="profile.form"
          :portfolioEdit="portifolio.form"
          class="topping"
        />
      </div>
      <confirmation
        v-model="showFinalModal"
        title="Concluído!"
        text="Parabéns, seu perfil foi salvo com sucesso!"
        confirmButtonText="Ver perfil"
        confirmButtonClass="btn-primary"
        cancelButtonText="Fechar"
        @confirm="
          showFinalModal = $router.push({
            name: 'professional-profile',
            params: { slug: $user.profile.slug }
          })
        "
        @cancel="showFinalModal = false"
      />
    </div>
  </action-dispatcher>
</template>
<script>
import NewService from "@/components/professional/NewService";
import ProfessionalProfile from "@/components/professional/Profile.vue";
import NewImagePortifolio from "@/components/professional/NewImagePortifolio.vue";
import {
  formFields,
  clearObject,
  convertHTMLToPlainText,
  firstLetterUppercase
} from "@/functions/helpers";
import { mapState } from "vuex";
import axios from "axios";
export default {
  components: { NewService, ProfessionalProfile, NewImagePortifolio },
  data() {
    return {
      loadingProfile: false,
      loadingService: false,
      loadingPage: false,
      clearAfterOptionsUpdate: false,
      removePhoto: false,
      isFirstLoad: true,
      showProfile: false,
      showFinalModal: false,
      profile: formFields(
        [
          "id",
          "name",
          "site",
          "email",
          "about",
          "social_media",
          "perfil_thumbnail",
          "phone",
          "photo",
          "user",
          "category",
          "tags",
          "state",
          "city",
          "country",
          "zipcode",
          "cover",
          "bio",
          "company_name"
        ],
        {
          tags: [],
          category: ""
        }
      ),
      services: [],
      services_no_edit: [],
      zipcode_error: false,
      portifolio: formFields([
        "archives_portfolio",
        { archives_portfolio: { file: "", description: "" } }
      ]),
      loadingPortifolio: false,
      showButtonsPort: false
    };
  },
  watch: {
    // "profile.form.category"(cat_id) {
    //    this.$store
    //     .dispatch("professional/getTagsByCategories", cat_id || "")
    //     .then();
    // },
    "profile.form.zipcode"(val) {
      if (val.replace(/\D/g, "").length === 8) {
        this.getZipCode(val);
      }
    },
    "profile.form.perfil_thumbnail"(val) {
      this.profile.form.photo = val;
    }
  },
  mounted() {
    Promise.all([
      //this.$store.dispatch("professional/getCategories"),

      this.$store.dispatch("professional/getTags")
    ])
      .finally(() => {
        this.getProfile();
      })
      .catch(errors => errors.map(err => this.$message.error(err)))
      .finally(() => (this.loading = false));
    this.$store
      .dispatch("portfolio/getThirdPortfolio", this.profile.form.id)
      .then(() => {
        this.fillPortfolio();
      });
  },
  computed: {
    ...mapState("professional", ["categories", "tags"]),
    ...mapState("portfolio", ["portfolio"]),
    tagList() {
      if (this.tagsAux && this.tagsAux.length && this.tags.length == 0) {
        return [...this.tagsAux, ...this.tags];
      }

      return this.tags;
    },
    isValid() {
      let valid = true;
      for (let key in this.profile.form) {
        valid = !!this.profile.form[key];
      }

      return valid;
    },
    canCreateService() {
      return !!this.profile.form?.id;
    }
  },
  methods: {
    fillPortfolio() {
      if (this.portfolio) {
        this.portifolio.form.archives_portfolio = this.portfolio.archives_portfolio.map(
          ({ id, description, file }) => {
            return { id, description, file };
          }
        );
      }
    },
    addImage() {
      this.portifolio.form.archives_portfolio = [
        ...this.portifolio.form.archives_portfolio,
        {
          archives_portfolio: { file: "", description: "" }
        }
      ];
      this.showButtonsPort = true;
    },
    cancelPortifolio() {
      this.fillPortfolio();
      this.showButtonsPort = false;
    },
    savePortifolio() {
      this.showButtonsPort = false;
      let payload = { archives_portfolio: [] };
      for (let i in this.portifolio.form.archives_portfolio) {
        payload.archives_portfolio.push(
          this.makePayloadPort(this.portifolio.form.archives_portfolio[i])
        );
      }
      if (!this.portfolio) {
        this.$store
          .dispatch("portfolio/createPortfolio", payload)
          .then(() => {
            this.$message.success("Portfolio criado com sucesso");
          })
          .catch(() => {
            this.$message.error("Houve um erro ao salvar o portfolio");
          });
      } else {
        this.$store
          .dispatch("portfolio/updatePortfolio", {
            id: this.portfolio.id,
            data: payload
          })
          .then(() => {
            this.$message.success("Portfolio atualizado com sucesso");
            this.$store.dispatch("portfolio/getPortfolio").then(() => {
              this.fillPortfolio();
            });
          })
          .catch(() => {
            this.$message.error("Houve um erro ao salvar portfolio");
          });
      }
    },
    makePayloadPort(portifolio) {
      if (Array.isArray(portifolio.file) && portifolio.file[0]?.base64) {
        return {
          file: portifolio.file[0].base64,
          description: portifolio.description
        };
      } else {
        if (portifolio.description.length) {
          return {
            file: portifolio.file,
            description: portifolio.description,
            id: portifolio.id
          };
        }
        return {
          file: portifolio.file,
          description: null,
          id: portifolio.id
        };
      }
    },
    makePayload(service) {
      let payload = { ...service };
      if (payload.form.name) {
        payload.form.certificate = payload.form.name[0];
        delete payload.form.name;
      }
      //this if check if user add new images
      if (
        payload.form.archives_service?.length &&
        payload.form?.archives_service[0]?.base64
      ) {
        let base64 = [];
        for (let i = 0; i < payload.form.archives_service.length; i++) {
          base64.push(payload.form.archives_service[i]?.base64);
          delete payload.form.archives_service[i].src;
          delete payload.form.archives_service[i].base64;
          delete payload.form.archives_service[i].file;
        }
        for (let i = 0; i < base64.length; i++) {
          payload.form.archives_service[i].file = base64[i];
        }
      }
      delete payload.form.is_editing;
      return payload;
    },
    getZipCode(val) {
      axios.get(`https://viacep.com.br/ws/${val}/json/`).then(({ data }) => {
        if (!data.erro) {
          this.profile.form.zipcode = data.cep;
          this.profile.form.state = data.uf;
          this.profile.form.city = data.localidade;
          this.profile.form.country = "Brasil";
        } else {
          this.zipcode_error = true;
        }
      });
    },
    getProfile() {
      return this.$store
        .dispatch("user/getMyProfile")
        .then(data => {
          if (data?.id) {
            for (let key in this.profile.form) {
              if (key == "about") {
                this.profile.form[key] =
                  convertHTMLToPlainText(data[key]) || null;
                continue;
              }

              if (key == "category") {
                this.profile.form[key] = data[key]?.id || null;
                continue;
              }

              if (key == "tags") {
                this.profile.form[key] = data[key]?.map(({ id }) => id) || null;
                this.tagsAux = data[key] || null;
                continue;
              }

              if (key == "user") {
                this.profile.form[key] = this.$user.user.id;
                continue;
              }

              this.profile.form[key] = data[key] || "";
            }

            this.profile.form.id = data?.id || "";

            this.getPerfilServices();
          }
        })
        .catch(err => {
          const error = err?.response?.data;
          if (error?.error == "Perfil not exists!") {
          }
        });
    },
    cancelService(service, index) {
      if (!service.form.id) {
        this.services = [...this.services.filter((_, i) => index !== i)];
      } else {
        this.getService(service);
        this.getPerfilServices();
      }
    },
    addService() {
      if (!this.canCreateService) {
        this.$message.error(
          "Para adicionar serviço você deve criar um perfil profissional"
        );
        return;
      }

      this.services = [
        ...this.services,
        {
          ...formFields(["name", "description", "perfil", "archives_profile"], {
            perfil: this.profile.form.id
          })
        }
      ];
    },
    getPerfilServices() {
      this.$store
        .dispatch("services/getServicesUser", this.$user.user.id)
        .then(response => {
          let results = response.data.results;
          this.services = results.map(result => {
            result.is_editing = false;
            return {
              form: {
                ...result,
                description: convertHTMLToPlainText(
                  result.certificate.description
                )
              },
              errors: {}
            };
          });
          this.services_no_edit = { ...this.services };
        })
        .catch(error => {
          const data = error.response.data;

          this.$message.error(
            data?.error ||
              data?.detail ||
              data?.message?.join(".\n") ||
              "Não foi possível carregar seus serviçøs"
          );
        })
        .finally(() => {
          this.loadingService = false;
        });
    },
    submitService(service) {
      this.loadingService = true;
      const submitFunc = service.form.id ? this.updateService : this.newService;
      let payload = this.makePayload(service);

      this.loadingService = true;
      submitFunc(payload)
        .then(() => (service.form.is_editing = false))
        .finally(() => {
          this.loadingService = false;
        });
    },
    newService(service) {
      if (!service.form.description.length) {
        this.$message.error("O campo de descrição é obrigatório!");
        return;
      }
      return this.$store
        .dispatch("services/createService", service.form)
        .then(() => {
          this.$message.success("O novo serviço foi adicionado!");
          service.form.is_editing = false;
        })
        .catch(error => {
          const data = error.response.data;

          this.$message.error(
            data?.error ||
              data?.detail ||
              data?.message?.join(".\n") ||
              "Não foi possível salvar o novo serviçø"
          );
        })
        .finally(() => this.getPerfilServices());
    },
    getService(service) {
      this.loadingService = true;

      this.$store
        .dispatch("user/getService", service.form.id)
        .then(({ data }) => {
          service.form = { ...data, is_editing: false };
        })
        .finally(() => {
          this.loadingService = false;
        });
    },
    updateService(service) {
      const is_deleting_service = service.form.name === "";
      const message_success_update = is_deleting_service
        ? "O serviço foi deletado com sucesso!"
        : "O serviço foi atualizado com sucesso!";
      const message_error_update = is_deleting_service
        ? "Ocorreu um erro ao tentar deletar o serviço!"
        : "Ocorreu um erro ao tentar atualizar o serviço!";

      if (is_deleting_service) {
        service.form.is_active = false;
        delete service.form.name;
      }

      return this.$store
        .dispatch("services/updateService", {
          data: service.form,
          id: service.form.id
        })
        .then(() => {
          if (is_deleting_service) {
            this.services = this.services.filter(
              serv => serv.id != service.form.id
            );
          }

          service.form.is_active = false;
          service.form = { ...service.form, is_editing: false };
          this.$message.success(message_success_update);
        })
        .catch(error => {
          const data = error.response.data;

          this.$message.error(
            data?.error ||
              data?.detail ||
              data?.message?.join(".\n") ||
              message_error_update
          );
        })
        .finally(() => {
          this.getPerfilServices();
        });
    },
    submitProfile() {
      this.loadingProfile = true;

      this.profile.form.name = firstLetterUppercase(this.profile.form.name);

      if (this.profile.form.site) {
        if (this.profile.form.site.includes("www")) {
          let result = this.profile.form.site.replace("www.", "https://");
          this.profile.form.site = result;
        }
      }
      if (
        !this.profile.form.cover ||
        this.profile.form.cover.indexOf("base64") == -1
      ) {
        delete this.profile.form.cover;
      }
      const action = this.profile.form.id
        ? "user/updateProfile"
        : "user/createProfile";

      const payload = {
        ...this.profile.form,
        user: this.$user.user.id,
        name: this.profile.form.name || this.$user.user.name
      };

      if (this.removePhoto) {
        payload.photo = null;
      } else {
        if (this.profile.form.photo.indexOf("base64") == -1)
          delete payload.photo;
      }

      this.$store
        .dispatch(action, payload)
        .then(data => {
          //this.$message.success("Seus dados do perfil foram atualizados!");
          this.$store.dispatch("user/get").finally(() => {
            this.showFinalModal = true;
          });
        })
        .catch(error => {
          const data = error?.response?.data;

          if (!data || !data?.error) {
            this.$message.error(
              "Não foi possível publicar seu perfil! Entre em contato com o suporte"
            );
            return;
          }

          if (data?.error == "Perfil already exists!") {
            this.$message.error("Você já tem um perfil criado");
            return;
          }

          let msg = "";
          for (let k in data.error) {
            if (Array.isArray(data.error[k])) msg = data.error[k].join(". ");
          }

          if (msg.length) this.$message.error(msg);
        })
        .finally(() => {
          this.loadingProfile = false;
        });
    },
    excludeService(service) {
      this.loadingService = true;
      this.$store
        .dispatch("services/deleteService", service.form.id)
        .then(() => {
          this.getPerfilServices();
          this.$message.success("Serviço deletado com sucesso!");
        })
        .catch(error => {
          this.$message.error("Não foi possivel excluir esse serviço");
        })
        .finally(() => {
          this.loadingService = false;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.views-professionals-my-profile {
  header {
    background: #cfcfcf;
    height: 10%;
    width: 100%;
  }

  .half-up-card,
  .half-down-card {
    background: #f5f5f5;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
    border-radius: 16px 16px 0px 0px;

    width: 70%;
    @media screen and (max-width: 991px) {
      width: 90%;
    }
  }

  .half-up-card {
    border-radius: 0px 0px 16px 16px;
  }

  .cover-input-placeholder {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid #202124;
    border-radius: 4px;
    min-width: 165px;
    padding: 8px 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.015em;
      color: #202124;
    }

    i {
      font-size: 24px;
      color: #202124;
      margin-right: 5px;
    }
  }

  .profile-img-placeholder {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));

    .icon-wrapper {
      background: #ffcc00;
      border: 1px solid #f5f5f5;
      border-radius: 24px;
      width: 40px;
      height: 40px;

      display: flex;
      justify-content: center;
      align-items: center;

      position: absolute;
      top: 100%;
      left: 110%;
      transform: translate(-100%, -110%);
      i {
        font-size: 26px;
        color: #0a0a0a;
      }
    }
  }

  .btn-cover {
    background: transparent;
    border: 1px solid #0a0a0a;
    border-radius: 4px;
    color: #0a0a0a;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    transition: all 1s;
    i {
      font-size: 18px;
    }
    &:hover {
      color: #ffffff;
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .btn-service {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.015em;
    color: #bdbdbd;

    i {
      font-size: 28px;
    }

    &.enable {
      color: #202124;
      i {
        color: #202124;
      }
    }
  }

  .btn-image {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.015em;
    i {
      font-size: 28px;
    }
    color: #202124;
    i {
      color: #202124;
    }
  }

  .bio-input {
    small {
      font-weight: 700;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.015em;
      color: #595959;
    }
  }

  .profile-preview {
    .cover-profile {
      background-color: #cfcfcf;
      width: 100%;
      height: 250px;
      //padding-bottom: 0% !important;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    .topping {
      @media screen and (min-width: 992px) {
        margin-top: 8rem;
      }
      margin-top: -2rem;
    }
  }
}
</style>
